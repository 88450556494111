/* global tw */
import React from 'react';
import axios from 'axios';
import styled from 'react-emotion';
import 'typeface-cantata-one';
import 'typeface-open-sans';
import { Parallax, ParallaxLayer } from 'react-spring/dist/addons';
import SEO from '../components/SEO';
import SVG from '../components/SVG';
import ProjectCard from '../components/ProjectCard';
import { rotate, UpDown, UpDownWide, waveAnimation } from '../styles/animations';
import { hidden } from '../styles/utils';
import { colors } from '../../tailwind';
import triangle from '../images/triangle.svg';
import avatar from '../images/avatar.jpg';
import '../styles/global';

const Divider = styled(ParallaxLayer)`
  ${tw('absolute w-full h-full')};
  background: ${props => props.bg};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`;

const DividerMiddle = styled(Divider)`
  clip-path: polygon(0 15%, 100% 25%, 100% 85%, 0 75%);
`;

const Content = styled(ParallaxLayer)`
  ${tw('p-6 md:p-12 lg:p-24 justify-center items-center flex z-50')};
`;

const Hero = styled.div`
  ${tw('w-full xl:w-2/3')};
`;

const Inner = styled.div`
  ${tw('w-full xxl:w-2/3 text-center lg:text-left')};
`;

const BigTitle = styled.h1`
  ${tw('text-5xl lg:text-6xl font-serif text-white mb-6 tracking-wide')};
  text-shadow: 0 5px 35px rgba(255, 255, 255, 0.15);
`;

const Title = styled.h1`
  ${tw('text-4xl lg:text-4xl font-serif text-white mb-8 tracking-wide relative inline-block')};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${triangle});
    position: absolute;
    background-size: 40px;
    animation: ${rotate} 4s linear infinite;
    left: -60px;
    top: 5px;
  }
`;

const Subtitle = styled.p`
  ${tw('text-2xl lg:text-4xl font-sans text-white mt-8 xxl:w-3/4')};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
`;

const ProjectsWrapper = styled.div`
  ${tw('flex flex-wrap justify-between mt-8')};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

const WaveWrapper = styled.div`
  ${tw('absolute pin-b w-full')};
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const InnerWave = styled.div`
  ${tw('relative h-full')};
  svg {
    width: 100%;
    height: 40vh;
  }
`;

const AboutHero = styled.div`
  ${tw('flex flex-col lg:flex-row items-center mt-8')};
`;

const Avatar = styled.img`
  ${tw('rounded-full w-32 xl:w-48 shadow-lg h-auto')};
`;

const AboutSub = styled.span`
  ${tw('text-white pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl')};
`;

const AboutDesc = styled.p`
  ${tw('text-grey-light text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify')};
`;

const ContactText = styled.p`
  ${tw('text-grey-light font-sans text-xl md:text-2xl lg:text-3xl')};
  a {
    color: #e07628;
    text-decoration: none;
  }
`;

const Footer = styled.footer`
  ${tw('text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg')};
  a {
    color: #e07628;
    text-decoration: none;
  }
`;

const InputSet = styled.fieldset`
  position: absolute;
  width: 300px;
  height: 60px;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  border: 2px solid #fff;
  /* opacity: 0; */
  input, p {
    display: inline-block;
    width: 242px;
    margin-left: 50px;
    background: rgba(0, 0, 0, 0);
    color: #e07628;
    font-size: 14px;
    letter-spacing: 1px;
  }
  input {
    height: 40px;
    margin-top: 8px;
    border: none;
    outline: none;
  }
  p {
    margin-top: 22px;
    text-align: center;
  }
  .icon {
    position: absolute;
    width: 30px;
    height: 30px;
    top: 15px;
    svg {
      fill: #fff;
    }
  }
`;

const InputSetText = styled.fieldset`
  position: absolute;
  width: 320px;
  height: 200px;
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  border: 2px solid #fff;
  /* opacity: 0; */
  textarea, p {
    display: inline-block;
    width: 257px;
    margin: 0 10px;
    background: rgba(0, 0, 0, 0);
    color: #e07628;
    font-size: 14px;
    letter-spacing: 1px;
  }
  textarea {
    height: 180px;
    margin-top: 8px;
    border: none;
    outline: none;
    resize: none;
  }
  p {
    margin-top: 22px;
    text-align: center;
  }
`;

const SendContact = styled.button`
  border: 2px solid;
  background: rgba(0, 0, 0, 0);
  color: #e07628;
  border-color: #e07628;
  border-radius: 10px;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

class Index extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
    console.log(event.target.value);
  };

  handleSubmit = e => {
    fetch('?no-cache=1/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...this.state }),
    })
      .then(res => console.log(res))
      .catch(error => console.log(error));

    e.preventDefault();
  };

  render() {
    const { name, email, message } = this.state;

    return (
      <React.Fragment>
        <SEO />
        <Parallax pages={5}>
          <Divider speed={0.2} offset={0}>
            <UpDown>
              <SVG icon="triangle" className={hidden} width={48} stroke={colors.orange} left="10%" top="20%" />
              <SVG icon="hexa" width={48} stroke={colors.red} left="60%" top="70%" />
              <SVG icon="box" width={6} fill={colors['grey-darker']} left="60%" top="15%" />
            </UpDown>
            <UpDownWide>
              <SVG icon="arrowUp" className={hidden} width={16} fill={colors['blue-dark']} left="80%" top="10%" />
              <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="50%" />
              <SVG icon="circle" width={16} fill={colors['grey-darker']} left="70%" top="90%" />
              <SVG icon="triangle" width={16} stroke={colors['grey-darkest']} left="30%" top="65%" />
              <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="75%" top="10%" />
              <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" className={hidden} width={24} fill={colors['grey-darker']} left="5%" top="70%" />
            <SVG icon="circle" width={6} fill={colors['grey-darkest']} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="50%" top="60%" />
            <SVG icon="upDown" width={8} fill={colors['grey-darkest']} left="95%" top="90%" />
            <SVG icon="upDown" className={hidden} width={24} fill={colors['grey-darker']} left="40%" top="80%" />
            <SVG icon="triangle" width={8} stroke={colors['grey-darker']} left="25%" top="5%" />
            <SVG icon="circle" width={64} fill={colors.green} left="95%" top="5%" />
            <SVG icon="box" className={hidden} width={64} fill={colors.purple} left="5%" top="90%" />
            <SVG icon="box" width={6} fill={colors['grey-darkest']} left="10%" top="10%" />
            <SVG icon="box" width={12} fill={colors['grey-darkest']} left="40%" top="30%" />
            <SVG icon="hexa" width={16} stroke={colors['grey-darker']} left="10%" top="50%" />
            <SVG icon="hexa" width={8} stroke={colors['grey-darker']} left="80%" top="70%" />
          </Divider>
          <Content speed={0.4} offset={0}>
            <Hero>
              <BigTitle>
                Hola, Somos
                <br /> Negocios Exitosos.
              </BigTitle>
              <Subtitle>
                Creamos experiencias web que sí venden, para la próxima generación digital de consumidores que enfrentan los negocios.
              </Subtitle>
              {/* <Subtitle>I'm creating noice web experiences for the next generation of consumer-facing companies.</Subtitle> */}
            </Hero>
          </Content>
          <DividerMiddle
            bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
            speed={-0.2}
            offset={1.1}
            factor={2}
          />
          <Content speed={0.4} offset={1.2} factor={2}>
            <Inner>
              <Title>Página web profesional desde 4,985.00 MXN (+ IVA)</Title>
              <ProjectsWrapper>
                <ProjectCard
                  title="Diseño Moderno"
                  link="https://www.behance.net/gallery/58937147/Freiheit"
                  bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
                >
                  Página web adaptable a todos los tamaños de dispositivos.
                </ProjectCard>
                <ProjectCard
                  title="SEO"
                  link="https://www.behance.net/gallery/52915793/Harry-Potter"
                  bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
                >
                  SEO básico amigable con el usuario para que te posiciones en los buscadores como Google, Bing o Yahoo.
                </ProjectCard>
                <ProjectCard
                  title="Página de aterrizaje para que vendas"
                  link="https://www.behance.net/gallery/43907099/Tomb-Raider"
                  bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)"
                >
                  Landing One-Page con hasta 5 secciones, para ventas en internet más efectivas.
                </ProjectCard>
                <ProjectCard
                  title="Hosting y Dominio"
                  link="https://www.behance.net/gallery/38068151/Eagle"
                  bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
                >
                  Hospedaje de tu Página de Internet y Nombre de URL .com o .com.mx por 1 año con cuentas de correo electrónico personalizadas.
                </ProjectCard>
              </ProjectsWrapper>
            </Inner>
          </Content>
          <Divider speed={0.1} offset={1} factor={2}>
            <UpDown>
              <SVG icon="box" width={6} fill={colors.white} left="85%" top="75%" />
              <SVG icon="upDown" width={8} fill={colors.teal} left="70%" top="20%" />
              <SVG icon="triangle" width={8} stroke={colors.orange} left="25%" top="5%" />
              <SVG icon="circle" className={hidden} width={24} fill={colors.white} left="17%" top="60%" />
            </UpDown>
            <UpDownWide>
              <SVG icon="arrowUp" className={hidden} width={16} fill={colors.green} left="20%" top="90%" />
              <SVG icon="triangle" width={12} stroke={colors.white} left="90%" top="30%" />
              <SVG icon="circle" width={16} fill={colors.yellow} left="70%" top="90%" />
              <SVG icon="triangle" className={hidden} width={16} stroke={colors.teal} left="18%" top="75%" />
              <SVG icon="circle" width={6} fill={colors.white} left="75%" top="10%" />
              <SVG icon="upDown" className={hidden} width={8} fill={colors.green} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors.pink} left="80%" top="60%" />
            <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
            <SVG icon="box" width={12} fill={colors.yellow} left="29%" top="26%" />
            <SVG icon="hexa" width={16} stroke={colors.red} left="75%" top="30%" />
            <SVG icon="hexa" width={8} stroke={colors.yellow} left="80%" top="70%" />
          </Divider>
          <Divider bg="#23262b" clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={3} />
          <Divider speed={0.1} offset={3}>
            <UpDown>
              <SVG icon="box" className={hidden} width={6} fill={colors.blue} left="50%" top="75%" />
              <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
              <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
              <SVG icon="upDown" className={hidden} width={24} fill={colors.orange} left="80%" top="80%" />
            </UpDown>
            <UpDownWide>
              <SVG icon="arrowUp" className={hidden} width={16} fill={colors.purple} left="5%" top="80%" />
              <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
              <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
              <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
            <SVG icon="box" width={6} fill={colors.orange} left="10%" top="10%" />
            <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
            <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
          </Divider>
          <Content speed={0.4} offset={3}>
            <Inner>
              <Title>¿Por qué elegir nuestro servicio?</Title>
              <AboutHero>
                <Avatar src={avatar} alt="John Doe" />
                <AboutSub>
                  Las palabras no pueden capturar completamente lo profundo y complejo de nuestros pensamientos. 
                  Es por eso que describiremos nuestra emoción para expresarnos mejor con Emojis.
                  {/* The English language can not fully capture the depth and complexity of my thoughts. So I'm incorporating
                  Emoji into my speech to better express myself. Winky face. */}
                </AboutSub>
              </AboutHero>
              <AboutDesc>
                Sabemos como te sientes cuando buscas un producto que satisfaga tus necesidades, hay cientos de opciones en el mercado.
                <br/>
                <br/>
                Dejame decirte una cosa, no sé si somos tu mejor opción, sólo somos un negocio en crecimiento como tu, pero nos esforzaremos
                para adaptarnos al proyecto que tines en mente y crecer juntos de la mano, nuestra prioridad es que recuperes tu inversión
                y la multipliques cada vez más.
                <br/>
                <br/>
                Si compras tu página web con nosotros no sólo obtendras un producto facinante que puedes cambiar a tu antojo, con cientos de
                plantillas a escoger que tu mismo puedes personalizar sin tener conocimientos técnicos sofisticados, podras cambiarlo 
                las veces que quieras de por vida con 1 sólo pago.
                <br/>
                <br/>
                Además también tendrás soporte y asesoría gratuita por 1 año, ya sea en WhatsApp, Correo o Teléfono, con nuestro personal 
                en areas como Marketing Digital (Facebook Ads, Google AdWords, Inbound Marketing, etc), Finanzas, Impuestos, Nóminas,
                Gestión de Costos y Presupuestos, Gestión de Capital Humano, Canales de venta por Internet, Administración de Negocios 
                y ayuda siempre sobre tu Nueva Página Web.
                {/* You know the way you feel when you see a picture of two otters holding hands? That's how you're gonna feel
                every day. My mother cried the day I was born because she knew she’d never be prettier than me. You should
                make me your campaign manager. I was born for politics. I have great hair and I love lying. Captain? The
                kids want to know where Paulie the Pigeon is. I told them he got sucked up into an airplane engine, is that
                all right? */}
              </AboutDesc>
            </Inner>
          </Content>
          <Divider fill="#23262b" speed={0.2} offset={4}>
            <WaveWrapper>
              <InnerWave>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 338.05" preserveAspectRatio="none">
                  <path className={waveAnimation}>
                    <animate
                      attributeName="d"
                      values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                      repeatCount="indefinite"
                      dur="30s"
                    />
                  </path>
                </svg>
              </InnerWave>
            </WaveWrapper>
          </Divider>
          <Content speed={0.4} offset={4}>
            <Inner>
              <Title>Contactanos</Title>
              <ContactText>
                {/* Di <a href="mailto:hola@negociosexitoso.com.mx">Hola!</a> */}
                Di <a href="tel:5540452440">Hola!</a>{" "}<a href="tel:5540452440">Celular o WhatsApp: 55 4045 2440</a>
                {/* o encuentranos en otras plataformas:{' '} */}
                {/* <a href="https://dribbble.com/LekoArts">Facebook</a> &{' '} */}
                {/* <a href="https://www.instagram.com/lekoarts.de/">Instagram</a> */}
              </ContactText>
              {/* <form name="contact" method="POST" netlify-honeypot="bot-field" data-netlify="true"> */}
              <form name="contact" onSubmit={this.handleSubmit} data-netlify="true" data-netlify-honeypot="bot">
                <InputSet className="name enable">
                  <div className="icon left">
                    {/* <i className="user"></i> */}
                    <svg className="material-icon user" viewBox="0 0 24 24">
                      <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1S9.9 9.16 9.9 8s.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
                    </svg>
                  </div>
                  <input type="text" name="name" onChange={this.handleChange("name")} value={name} placeholder="Tu Nombre" />
                </InputSet>
                <br />
                <br />
                <br />
                <br />
                <InputSet className="email">
                  <div className="icon left">
                    <svg className="material-icon email" viewBox="0 0 24 24">
                      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                    </svg>
                  </div>
                  <input type="email" name="email" onChange={this.handleChange("email")} value={email} placeholder="Correo Electrónico" />
                </InputSet>
                <br />
                <br />
                <br />
                <br />
                <InputSetText className="email">
                  <textarea name="message" onChange={this.handleChange("message")} value={message} placeholder="Tu Mensaje" maxLength="250" />
                </InputSetText>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <p>
                  <SendContact type="submit">Enviar Mensaje</SendContact>
                </p>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </form>
            </Inner>
            <Footer>
              &copy; 2018 por <a href="https:/www.negociosexitosos.com.mx">Negocios Exitosos</a>
            </Footer>
          </Content>
          <Divider speed={0.1} offset={4}>
            <UpDown>
              <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="70%" top="20%" />
              <SVG icon="triangle" width={8} stroke={colors['grey-darkest']} left="25%" top="5%" />
            </UpDown>
            <UpDownWide>
              <SVG icon="triangle" width={12} stroke={colors.white} left="95%" top="50%" />
              <SVG icon="circle" width={6} fill={colors.white} left="85%" top="15%" />
              <SVG icon="upDown" className={hidden} width={8} fill={colors['grey-darkest']} left="45%" top="10%" />
            </UpDownWide>
            <SVG icon="circle" width={6} fill={colors.white} left="4%" top="20%" />
            <SVG icon="circle" width={12} fill={colors['grey-darkest']} left="70%" top="60%" />
            <SVG icon="box" width={12} fill={colors['grey-darkest']} left="20%" top="30%" />
            <SVG icon="hexa" width={8} stroke={colors['grey-darkest']} left="80%" top="70%" />
          </Divider>
        </Parallax>
      </React.Fragment>
    )
  }
};

export default Index;
